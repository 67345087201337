'use client'

import useGoogleAdvert from '@hooks/useGoogleAdvert'
import { global } from '@store/index'
import classNames from 'classnames'
import { assign, forEach, isArray, isFunction, max } from 'lodash-es'
import Script from 'next/script'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

export default function GoogleAdvert({
  adsize,
  adunit = 'rectangle',
  adpos,
  dfpPath,
  dfpTags,
  id,
  showTitle = true,
  showSub = true,
  className,
  style,
  onLoad,
  desktopVisible = true,
  mobileVisible = true
}) {

  const { mobile } = useRecoilValue(global)
  useGoogleAdvert({ dfpPath, dfpTags })
  useEffect(() => {
    window.dfp_slotCB = function callbackHandle(event) {
      let domId = event.slot.getSlotElementId()
      let slot = document.getElementById(domId)
      let parent = slot ? slot.parentNode : null
      if (slot) {
        slot.classList.remove('bone')
        slot.style.minWidth = 'auto'
        slot.style.minHeight = 'auto'

        const iframe = slot.querySelector('iframe')
        if (iframe) parent.style.width = `${iframe.offsetWidth}px`
      }
      if (event.isEmpty) {
        parent && (parent.style.display = 'none')
        if (id === domId && isFunction(onLoad)) onLoad(false)
      } else {
        // parent && (parent.style.display = 'block')
        if (id === domId && isFunction(onLoad)) onLoad(true)
      }
    }
  }, [id, onLoad])

  function initAd() {
    setTimeout(() => {
      if (window.googletag && window.initNZMEADS) {
        if (window.googletag.destroySlots instanceof Function) {
          window.googletag.destroySlots()
        }
        window.initNZMEADS()
      }
    }, 0);
  }

  if (mobile && !mobileVisible) return
  if (!mobile && !desktopVisible) return

  const [adstyle, wrapstyle] = getAdStyle(adsize)
  return (
    <>
      <div className={classNames('skeleton-wrap mx-auto max-w-full overflow-hidden', mobileVisible ? 'block' : 'hidden', desktopVisible ? 'sm:block' : 'sm:hidden', className)} style={assign(wrapstyle, style)}>
        {showTitle && <div className='text-neutral-5 text-center text-base mb-4'>ADVERTISEMENT</div>}
        <div className='dfpad bone' id={id} data-adsize={isArray(adsize) ? JSON.stringify(adsize) : adsize} data-adunit={adunit} data-adpos={adpos} style={adstyle}></div>
        {showSub && (
          <div className='text-xs text-right text-neutral-5 mt-4'>
            <a href='mailto:sales@oneroof.co.nz'><em>Advertise with OneRoof</em></a>
          </div>
        )}
      </div>
      <Script src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' strategy='lazyOnload'></Script>
      <Script src='https://nzme-ads.co.nz/js/nzmedfp.js' strategy='lazyOnload' onReady={initAd}></Script>
    </>
  )
}

export function GoogleAdvertPageBase({
  dfpPath,
  dfpTags
}) {
  useGoogleAdvert({ dfpPath, dfpTags })
}

GoogleAdvert.propTypes = {
  id: PropTypes.string.isRequired,
  adsize: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
GoogleAdvertPageBase.propTypes = {
  dfpPath: PropTypes.string.isRequired
}



function getAdStyle(adsize) {
  if (!adsize) return []
  const adSize = isArray(adsize) ? adsize : JSON.parse(adsize)
  const is2d = isArray2d(adSize)
  const width = []
  const height = []
  if (is2d) {
    forEach(adSize, item => {
      width.push(item[0])
      height.push(item[1])
    })
  } else {
    width.push(adSize[0])
    height.push(adSize[1])
  }

  return [
    { minWidth: max(width), minHeight: max(height) },
    { width: max(width) }
  ]
}

function isArray2d(arr) {
  let res = true
  forEach(arr, item => {
    if (!isArray(item)) res = false
  })
  return res
}