'use client'

import { removeLoading } from '@utils'
import { get } from 'lodash-es'
import Image, { getImageProps } from 'next/image'

export default function ImageBgLoading({
  src,
  width,
  height,
  alt = '',
  ...args
}) {

  return (
    <Image src={src || ''} width={width} height={height} alt={alt} {...args} imgbg='loading' onLoad={removeLoading} onError={removeLoading} />
  )
}

export function ResponsiveImageBgLoading({
  // web src
  src,
  width,
  height,
  // mobile src
  mobileSrc,
  mobileWidth = width,
  mobileHeight = height,
  // mobile landscape src
  mobileLandscapeSrc,
  mobileLandscapeWidth = width,
  mobileLandscapeHeight = height,
  // mobile portrait src
  mobilePortraitSrc,
  mobilePortraitWidth = width,
  mobilePortraitHeight = height,
  alt = '',
  className,
  wrapClassName,
  useLoading = true,
  priority = false,
  origin = false,
  unoptimized,
  ...args
}) {

  if (!src && !mobileLandscapeSrc && !mobilePortraitSrc) return

  const url = src || mobilePortraitSrc || mobileLandscapeSrc || ''
  const murl = mobileSrc || mobilePortraitSrc || mobileLandscapeSrc || src || ''
  const mLandscapeurl = mobileLandscapeSrc || mobilePortraitSrc || src || ''
  const mPortraiturl = mobilePortraitSrc || mobileLandscapeSrc || src || ''
  const isSvg = /.svg$/i.test(get(url, 'src', url))

  const common = { alt, unoptimized: isSvg || unoptimized, priority }
  const { props: { srcSet: desktop, ...rest } } = getImageProps({ ...common, src: url, width, height })
  const { props: { srcSet: mobile, ...mRest } } = getImageProps({ ...common, src: murl, width: mobileWidth, height: mobileHeight })
  const { props: { srcSet: mobileLandscape, ...mLandscapeRest } } = getImageProps({ ...common, src: mLandscapeurl, width: mobileLandscapeWidth, height: mobileLandscapeHeight })
  const { props: { srcSet: mobilePortrait, ...mPortraitRest } } = getImageProps({ ...common, src: mPortraiturl, width: mobilePortraitWidth, height: mobilePortraitHeight })

  return (
    <picture className={wrapClassName}>
      <source
        media="(min-width: 820px) and(pointer: fine), (min-width: 820px) and (hover: hover)"
        srcSet={isSvg || origin ? getSrc(url) : desktop}
        {...(origin ? {} : rest)}
      />
      {
        Boolean(mobileSrc) && (
          <source
            media="(max-width: 819px) and (pointer: coarse), (max-width: 819px) and (hover: none)"
            srcSet={isSvg || origin ? getSrc(mobileSrc) : mobile}
            {...(origin ? {} : mRest)}
          />
        )
      }
      {
        Boolean(mobileLandscapeSrc) && (
          <source
            media="(max-width: 819px) and (pointer: coarse) and (orientation: landscape), (max-width: 819px) and (hover: none) and (orientation: landscape)"
            srcSet={isSvg || origin ? getSrc(mobileLandscapeSrc) : mobileLandscape}
            {...(origin ? {} : mLandscapeRest)}
          />
        )
      }
      {
        Boolean(mobilePortraitSrc) && (
          <source
            media="(max-width: 819px) and (pointer: coarse) and (orientation: portrait), (max-width: 819px) and (hover: none) and (orientation: portrait)"
            srcSet={isSvg || origin ? getSrc(mobilePortraitSrc) : mobilePortrait}
            {...(origin ? {} : mPortraitRest)}
          />
        )
      }
      <Image
        {...({ ...rest, src: getSrc(url) })}
        {...args}
        unoptimized={origin ? true : unoptimized}
        alt={alt}
        className={className}
        {...(useLoading ? { imgbg: 'loading' } : undefined)}
        onLoad={removeLoading}
        onError={removeLoading}
      />
    </picture>
  )
}

function getSrc(src) {
  return get(src, 'src', src)
}
