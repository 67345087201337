import classNames from 'classnames'
import { isFunction } from 'lodash-es'

export default function InputContent({
  inputRef,
  type,
  defaultValue,
  placeholder,
  className,
  onChange,
  xs,
  sm,
  md,
  lg,
  xl,
  onBlur,
  onClick,
  error = false,
  textarea = false,
  disabled = false,
  label,
  icon,
  prefix,
  suffix,
  maxlength,
}) {

  function changeHandle(event) {
    isFunction(onChange) && onChange(event.target.value, event)
  }

  return textarea
    ? (
      <textarea
        ref={inputRef}
        disabled={disabled}
        value={defaultValue}
        placeholder={placeholder}
        onChange={changeHandle}
        className={
          classNames(
            'border rounded-sm w-full font-mono disabled:bg-neutral-1 disabled:cursor-not-allowed disabled:text-neutral-5 disabled:placeholder:text-neutral-4',
            { 'h-60 p-12 text-xs': xs },
            { 'h-80 p-14 text-sm': sm },
            { 'h-100 p-16 text-base': md },
            { 'h-140 p-16 text-lg': lg },
            { 'h-160 p-18 text-xl': xl },
            { 'border-red': error },
            className
          )
        }
        onBlur={onBlur}
        onClick={onClick}
        maxLength={maxlength}
        aria-label={label}
      ></textarea>
    )
    : (
      <div className={
        classNames(
          'border rounded-sm w-full flex',
          { 'border-red': error },
          { 'bg-neutral-1 cursor-not-allowed': disabled },
          className
        )
      }>
        {prefix}
        {icon && <i className={classNames('icon pl-16 self-center', icon)}></i>}
        <input
          ref={inputRef}
          type={type}
          disabled={disabled}
          value={defaultValue}
          placeholder={placeholder}
          onChange={changeHandle}
          className={
            classNames(
              'rounded-sm flex-1 min-w-0 disabled:cursor-not-allowed disabled:text-neutral-4 disabled:placeholder:text-neutral-4',
              { 'h-32 sm:h-36 px-12 text-xs': xs },
              { 'h-36 sm:h-40 px-14 text-sm': sm },
              { 'h-40 sm:h-46 px-16 text-base': md },
              { 'h-48 sm:h-52 px-18 text-lg': lg },
              { 'h-52 sm:h-54 px-20 text-xl': xl },
            )
          }
          onBlur={onBlur}
          onClick={onClick}
          aria-label={label}
          maxLength={maxlength}
        />
        {suffix}
      </div>
    )
}