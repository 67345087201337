import { subscribeUI, unSubscribeUI } from '@utils'
import { isArray } from 'lodash-es'
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
// import Schema from 'async-validator'

export const FormContext = createContext()
export function useFormContext() {
  return useContext(FormContext)
}

export function useFieldValidator(rule, value, prop) {
  const targetRef = useRef()
  const [error, setError] = useState(null)

  const verify = useCallback((event) => {
    const type = event ? (event.type === 'keyup' ? 'input' : event.type) : null
    if (!rule || !type || (isArray(rule.trigger) ? !rule.trigger.includes(type) : rule.trigger !== type)) return true

    return verifyData({ [prop]: rule }, { [prop]: value })
      .then(() => {
        setError(null)
        return true
      })
      .catch(({ errors }) => {
        setError(errors)
        return false
      })
  }, [value, rule, prop, setError])

  useEffect(() => {
    if (!targetRef.current) return

    const el = targetRef.current
    subscribeUI('blur', verify, el)
    subscribeUI('keyup', verify, el)

    return () => {
      unSubscribeUI('blur', verify, el)
      unSubscribeUI('keyup', verify, el)
    }

  }, [targetRef, verify])

  return [
    targetRef,
    error,
    verify
  ]
}

export async function verifyData(rules, data) {
  if (!rules) return Promise.resolve()
  const Schema = await getValidatorSchema()
  const validator = new Schema(rules)
  return validator.validate(data)
}
export async function getValidatorSchema() {
  return (await import('async-validator')).default
}