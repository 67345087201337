'use client'

import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

export default function Collapse({
  children,
  className,
  contentClassName,
  height = 0,
  show = false
}) {

  const [innerHeight, setInnerHeight] = useState(0)
  const ref = useRef(null)

  const style = useMemo(() => {
    let res
    if (show) {
      res = { height: innerHeight === 0 ? 'auto' : innerHeight }
    } else {
      res = { height }
    }
    return res
  }, [innerHeight, show, height])

  const { width } = useWindowSize()
  useEffect(() => {
    setInnerHeight(ref.current.offsetHeight)
  }, [ref, show, width])

  return (
    <div style={style} className={classNames('transition-[height]', { 'overflow-hidden': !show }, className)}>
      <div ref={ref} className={contentClassName}>{children}</div>
    </div>
  )
}