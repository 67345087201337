
// CONSTRUCTOR
// ===========

function CalculatorUtils() { }

// CONSTANTS
// =========

CalculatorUtils.WEEKLY = 'weekly'
CalculatorUtils.FORTNIGHTLY = 'fortnightly'
CalculatorUtils.MONTHLY = 'monthly'
CalculatorUtils.YEARLY = 'yearly'

CalculatorUtils.FLYBUYS_TIER_1_RATE = 9
CalculatorUtils.FLYBUYS_TIER_2_RATE = 34
CalculatorUtils.FLYBUYS_TIER_3_RATE = 84

CalculatorUtils.WEEKS_PER_YEAR = 365 / 7
CalculatorUtils.FORTNIGHTS_PER_YEAR = 365 / 14
CalculatorUtils.MONTHS_PER_YEAR = 12

// CLASS METHODS
// =============

/**
 * Limits a value to within a specified range.
 *
 * @param {Number} value The value to test against upper and lower limit
 * @param {Number} min The lower limit
 * @param {Number} max The upper limit
 * @return {Number} Value between min and max.
 */
CalculatorUtils.bound = function (value, min, max) {
  return Math.max(Math.min(value, max), min)
}

/**
 * Calculates repayment based on repayment frequency.
 *
 * @param {Number} ratePercent Interest rate in percent
 * @param {Number} termYears Term in years
 * @param {Number} principal Amount borrowed
 * @param {String} frequency Monthly (default), Fortnightly, Weekly
 * @return {Number} repayment.
 */
CalculatorUtils.calculateRepayment = function (
  ratePercent,
  termYears,
  principal,
  frequency
) {
  // Source: https://en.wikipedia.org/wiki/Mortgage_calculator#Monthly_payment_formula

  var r // interest rate (decimal)
  var N // number of payments
  var P = principal // amount borrowed

  if (frequency === CalculatorUtils.FORTNIGHTLY) {
    r = ratePercent / 100 / CalculatorUtils.FORTNIGHTS_PER_YEAR
    N = termYears * CalculatorUtils.FORTNIGHTS_PER_YEAR
  } else if (frequency === CalculatorUtils.WEEKLY) {
    r = ratePercent / 100 / CalculatorUtils.WEEKS_PER_YEAR
    N = termYears * CalculatorUtils.WEEKS_PER_YEAR
  } else {
    r = ratePercent / 100 / 12
    N = termYears * 12
  }

  return P * r * Math.pow(1 + r, N) / (Math.pow(1 + r, N) - 1) // divide by zero?
}

/**
 * Calculates term in fractional years.
 *
 * @param {Number} ratePercent Interest rate in percent
 * @param {Number} principal Amount borrowed
 * @param {String} frequency Monthly (default), Fortnightly, Weekly
 * @param {Number} repayment Repayment amount per frequency
 * @return {Number} Term in fractional years.
 */
CalculatorUtils.calculateTermYears = function (
  ratePercent,
  principal,
  frequency,
  repayment
) {
  // Source: https://en.wikipedia.org/wiki/Mortgage_calculator#Monthly_payment_formula
  // Monthly Payment Formula solved for N

  var r // interest rate (decimal)
  var N // number of payments
  var P = principal // amount borrowed
  var c = repayment // repayment per frequency

  if (frequency === CalculatorUtils.FORTNIGHTLY) {
    r = ratePercent / 100 / CalculatorUtils.FORTNIGHTS_PER_YEAR
  } else if (frequency === CalculatorUtils.WEEKLY) {
    r = ratePercent / 100 / CalculatorUtils.WEEKS_PER_YEAR
  } else {
    r = ratePercent / 100 / 12
  }

  N = -1 * Math.log(1 - r * P / c) / Math.log(1 + r)

  if (frequency === CalculatorUtils.FORTNIGHTLY) {
    return N / CalculatorUtils.FORTNIGHTS_PER_YEAR
  } else if (frequency === CalculatorUtils.WEEKLY) {
    return N / CalculatorUtils.WEEKS_PER_YEAR
  } else {
    return N / 12
  }
}

/**
 * Calculates total interest.
 *
 * @param {Number} ratePercent Interest rate in percent
 * @param {Number} termYears Term in years
 * @param {Number} principal Amount borrowed
 * @param {String} frequency Monthly (default), Fortnightly, Weekly
 * @return {Number} Total interest.
 */
CalculatorUtils.calculateTotalInterest = function (
  ratePercent,
  termYears,
  principal,
  frequency
) {
  // Source: https://en.wikipedia.org/wiki/Mortgage_calculator#Total_Interest_Paid_Formula

  var repayment = CalculatorUtils.calculateRepayment(
    ratePercent,
    termYears,
    principal,
    frequency
  )
  var cN // total payment amount
  var P = principal

  if (frequency === CalculatorUtils.FORTNIGHTLY) {
    cN = repayment * CalculatorUtils.FORTNIGHTS_PER_YEAR * termYears
  } else if (frequency === CalculatorUtils.WEEKLY) {
    cN = repayment * CalculatorUtils.WEEKS_PER_YEAR * termYears
  } else {
    cN = repayment * CalculatorUtils.MONTHS_PER_YEAR * termYears
  }

  return cN - P
}

/**
 * Calculates rewards earned up to first year.
 *
 * @param {Number} ratePercent Interest rate in percent
 * @param {Number} termYears Term in years
 * @param {Number} principal Amount borrowed
 * @param {String} frequency Monthly (only)
 * @param {String} rewardScheme "flybuys"
 * @return {Number} Rewards earned up to first year.
 */
CalculatorUtils.calculateRewards = function (
  ratePercent,
  termYears,
  principal,
  frequency,
  rewardScheme
) {
  // Source: http://TotalMoneyCalculator.java and http://MortgageRewardsCalculator.java
  // Currently only works for monthly repayments frequency, hence use of Calculator.MONTHLY

  var repayment = CalculatorUtils.calculateRepayment(
    ratePercent,
    termYears,
    principal,
    CalculatorUtils.MONTHLY
  )
  var loanBalance = principal
  var totalPoints = 0
  var rewardMonths = termYears < 1
    ? termYears * CalculatorUtils.MONTHS_PER_YEAR
    : CalculatorUtils.MONTHS_PER_YEAR

  if (rewardScheme === 'flybuys') {
    for (var i = 0; i < rewardMonths; i++) {
      if (loanBalance >= 150000) {
        totalPoints += CalculatorUtils.FLYBUYS_TIER_3_RATE
      } else if (loanBalance >= 50000) {
        totalPoints += CalculatorUtils.FLYBUYS_TIER_2_RATE
      } else if (loanBalance > 0) {
        totalPoints += CalculatorUtils.FLYBUYS_TIER_1_RATE
      }

      loanBalance =
        loanBalance -
        repayment +
        loanBalance * (ratePercent / 100) / CalculatorUtils.MONTHS_PER_YEAR
    }
  }

  return totalPoints
}

/**
 * Formats term in fractional years into years, years and months, or months string.
 *
 * @param {Number} termYears Term in fractional years
 * @return {String} Term in years, years and months, or months string.
 */
CalculatorUtils.formatTermAsYearsAndMonths = function (termYears) {
  // var years = Math.floor(termYears),
  //   months = Math.ceil((termYears - years) * CalculatorUtils.MONTHS_PER_YEAR),
  //   output = ''

  // Calculating months using Math.round() might be wrong,
  // but it makes the Homeloan Repayment Calculator repayment slider produce correct results,
  // that is, if you drag the slider to max and then min, the loan term will correctly display 30 years.
  var years = Math.floor(termYears)
  var months = Math.round(
    (termYears - years) * CalculatorUtils.MONTHS_PER_YEAR
  )
  var output = ''

  if (years === 0 && months === 0) {
    return '0 months'
  }

  if (months === CalculatorUtils.MONTHS_PER_YEAR) {
    years++
    months = 0
  }

  if (years > 0) {
    output += years + ' year'

    if (years > 1) {
      output += 's'
    }
  }

  if (months > 0) {
    if (years > 0) {
      output += ', '
    }

    output += months + ' month'

    if (months > 1) {
      output += 's'
    }
  }

  return output
}

export default CalculatorUtils
