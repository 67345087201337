import { CALCULATOR_REPAYMENT } from '@config'
import { find } from 'lodash-es'
import CalculatorUtils from './utils'

export default function calculatedLoan(data) {
  let frequency = find(CALCULATOR_REPAYMENT, item => item.value === data.frequency).alias.toLowerCase()
  let amountRequired = data.price - data.deposit
  let interest = CalculatorUtils.calculateTotalInterest(data.interestRate, data.loanTerm, amountRequired, frequency)
  let repay = CalculatorUtils.calculateRepayment(data.interestRate, data.loanTerm, amountRequired, frequency)
  return {
    total: Math.round(amountRequired + interest),
    repay: Math.round(repay),
    interest: Math.round(interest)
  }
}