import classNames from 'classnames'
import { useFormContext } from '../context'
import FormRow from '../row'
import CheckboxContent from './content'
import { includes, map } from 'lodash-es'

export default function FormCheckbox({
  prop,
  children,
  trueValue,
  falseValse,
  className
}) {

  const { formData, changeField } = useFormContext()
  function changeHandle(val) {
    changeField(prop, val)
  }
  return (
    <FormRow noError className={classNames('-mt-8', className)}>
      <CheckboxContent checked={formData[prop]} onChange={changeHandle} trueValue={trueValue} falseValse={falseValse}>{children}</CheckboxContent>
    </FormRow>
  )
}

export function FormCheckboxGroup({
  prop,
  data,
  label,
  required,
  multiple = false,
  className
}) {

  const { formData, changeField } = useFormContext()
  function changeHandle(val) {
    const d = formData[prop]
    if (multiple) {
      if (d.includes(val)) {
        d.splice(d.indexOf(val), 1)
      } else {
        d.push(val)
      }
      changeField(prop, d)
    } else {
      changeField(prop, val)
    }
  }

  return (
    <FormRow label={label} required={required} className={classNames('', className)}>
      <div className='flex items-start flex-col gap-y-20'>
        {
          map(data, (item, idx) => (
            <CheckboxContent key={idx} checked={multiple ? includes(formData[prop], item.value) : formData[prop] === item.value} onChange={changeHandle} trueValue={item.value} falseValse={multiple ? item.value : ''}>{item.label}</CheckboxContent>
          ))
        }
      </div>
    </FormRow>
  )
}