import classNames from 'classnames'
import { find, isEqual, isFunction, map } from 'lodash-es'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

export default function SelectContent({
  disabled = false,
  defaultValue,
  onChange,
  onClick,
  placeholder,
  data,
  xs,
  sm,
  md,
  lg,
  xl,
  error,
  className,
  innerClassName
}) {

  const selfRef = useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  useClickAway(selfRef, () => setShowPopup(false))

  const [value, setValue] = useState(defaultValue)
  const isActive = useCallback(item => {
    return value && item && (isEqual(item, value) || item.value === value || item.value === value.value)
  }, [value])

  // 界面上显示的文本
  const label = useMemo(() => {
    let res = placeholder
    if (value) {
      const cur = find(data, item => isActive(item, value))
      if (cur) res = cur.label
    }
    return res
  }, [value, data, placeholder, isActive])


  const itemClick = useCallback(item => {
    return () => {
      setValue(item)
      setShowPopup(false)
      isFunction(onChange) && onChange(item)
    }
  }, [onChange, setValue, setShowPopup])
  const handleClick = useCallback(() => {
    setShowPopup(!showPopup)
    isFunction(onClick) && onClick(!showPopup)
  }, [showPopup, setShowPopup, onClick])

  return (
    <div className={classNames('w-full relative', className)} ref={selfRef}>
      <div
        className={classNames(
          'flex items-center cursor-pointer border',
          showPopup ? 'rounded-t-sm' : 'rounded-sm',
          { 'border-red': error },
          { 'bg-neutral-1 cursor-not-allowed text-neutral-3': disabled },
          { 'h-32 sm:h-36 px-12 text-xs': xs },
          { 'h-36 sm:h-40 px-14 text-sm': sm },
          { 'h-40 sm:h-46 px-16 text-base': md },
          { 'h-48 sm:h-52 px-18 text-lg': lg },
          { 'h-52 sm:h-54 px-20 text-xl': xl },
          innerClassName
        )}
        onClick={handleClick}
      >
        <span className={classNames('flex-1', { 'text-neutral-3': !value })}>{label}</span>
        <i className={classNames('icon icon-arrow-down duration-150', { '-rotate-180': showPopup })}></i>
      </div>
      <div className={classNames(
        'absolute z-10 inset-x-0 top-full bg-white border border-t-0 rounded-b-sm max-h-280 overflow-auto',
        showPopup ? 'animate-expansion' : 'hidden'
      )}>
        <ul className='py-4 space-y-1'>
          {
            map(data, (item, idx) => (
              <li
                key={idx}
                className={classNames(
                  'h-36 px-10 flex items-center cursor-pointer text-sm hover:bg-primary-light hover:text-primary',
                  { 'text-primary bg-primary-light': isActive(item) }
                )}
                onClick={itemClick(item)}
              >{item.label}</li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}