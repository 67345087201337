import { first, get, isArray, isFunction, size } from 'lodash-es'
import { useFormContext, useFieldValidator } from '../context'
import FormRow from '../row'
import { useCallback, useEffect, useMemo } from 'react'
import InputContent from './content'

export default function FormInput({
  label,
  subText,
  labelRight,
  type = 'text',
  placeholder,
  prop,
  onBlur,
  onError,
  onClick,
  className,
  inputClassName,
  textarea,
  disabled,
  errors,
  required = false,
  noError = false,
  maxlength,
  icon,
  prefix,
  suffix,
}) {

  const { xs, sm, md, lg, xl, rules, formData, changeField, pushFieldsVerify } = useFormContext()
  const val = get(formData, prop, '')
  const rule = get(rules, prop)
  const [inputRef, validErrors, verify] = useFieldValidator(rule, val, prop)
  const hasError = useMemo(() => size(errors || validErrors) > 0, [validErrors, errors])
  const verifyFn = useCallback(() => {
    return verify(rule ? { type: isArray(rule.trigger) ? first(rule.trigger) : rule.trigger } : undefined)
  }, [verify, rule])

  useEffect(() => {
    pushFieldsVerify(prop, verifyFn)
  }, [verifyFn, pushFieldsVerify, prop])
  useEffect(() => {
    isFunction(onError) && onError(validErrors)
  }, [onError, validErrors])

  const changeHandle = useCallback((value) => {
    changeField(prop, value)
  }, [prop, changeField])

  return (
    <FormRow label={label} subText={subText} labelRight={labelRight} noError={noError} errors={errors || validErrors} required={required} className={className}>
      <InputContent
        inputRef={inputRef}
        type={type}
        defaultValue={val}
        placeholder={placeholder}
        onChange={changeHandle}
        onBlur={onBlur}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        error={hasError}
        textarea={textarea}
        disabled={disabled}
        className={inputClassName}
        label={label}
        icon={icon}
        prefix={prefix}
        suffix={suffix}
        maxlength={maxlength}
        onClick={onClick}
      ></InputContent>
    </FormRow>
  )
}