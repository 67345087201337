import { UUID_KEY } from '@auth/config'
import { assign } from 'lodash-es'
import { useEffect } from 'react'
import Cookies from 'js-cookie'

export default function useGoogleAdvert({
  dfpPath,
  dfpTags,
}) {

  useEffect(() => {

    window['dfp_site'] = 'oneroof'
    if (dfpPath) window['dfp_path'] = dfpPath
    if (dfpTags) window['dfp_tags'] = assign(dfpTags, { oid: dfpTags.oid ? `${dfpTags.oid}` : 'null' }, { uuid: Cookies.get(UUID_KEY) })

  }, [dfpPath, dfpTags])
}