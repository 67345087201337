import { isObject } from 'lodash-es'
import { useFormContext } from '../context'
import classNames from 'classnames'

export default function FormButton({
  children,
  onClick,
  outline = false,
  className,
  buttonClassName,
  disabled,
  buttonTypeClassName = 'button-primary'
}) {

  if (outline) buttonTypeClassName = 'button-primary-out'

  const { loading, formError, inline, labelWidth } = useFormContext()
  return (
    <div className={classNames({ 'flex gap-x-10': inline }, className)}>
      {inline && <span className='inline-block h-10' style={{ width: labelWidth }}></span>}
      <div className={classNames({ 'flex-1 min-w-0': inline })}>
        <div className='text-red text-sm leading-14 min-h-[14px] pb-6 text-left'>{isObject(formError) ? JSON.stringify(formError) : formError}</div>
        <button type='submit' disabled={disabled} className={classNames('w-full', buttonTypeClassName, buttonClassName)} onClick={onClick}>{loading ? 'loading...' : children}</button>
      </div>
    </div>
  )
}