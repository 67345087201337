'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormContext } from './context'
import classNames from 'classnames'
import { clone, get, includes, isEqual, isFunction, map } from 'lodash-es'
import { useBoolean } from 'react-use'

const FORM_SIZE = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
}
export default function Form({
  data,
  size = FORM_SIZE.md,
  rules,
  className,
  children,
  onChange,
  onSubmit,
  poster,
  inline = false,
  labelWidth,
}) {

  const [loading, toggleLoading] = useBoolean(false)
  const [formError, setFormError] = useState()
  const [formData, setFormData] = useState(data)
  const [fieldVerify, setFieldsVerify] = useState({})
  const [xs, sm, md, lg, xl] = useMemo(() => {
    return [
      size === FORM_SIZE.xs,
      size === FORM_SIZE.sm,
      size === FORM_SIZE.md,
      size === FORM_SIZE.lg,
      size === FORM_SIZE.xl
    ]
  }, [size])

  useEffect(() => {
    isFunction(onChange) && onChange(formData)
  }, [formData, onChange])

  const timer = useRef(null)
  useEffect(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setFormData(d => {
        if (isEqual(d, data)) return d
        return clone(data)
      })
    }, 100)
  }, [data, setFormData, timer])

  const changeField = useCallback((key, val) => {
    setFormData(d => ({ ...d, [key]: val }))
  }, [setFormData])
  const pushFieldsVerify = useCallback((key, val) => {
    setFieldsVerify(d => ({ ...d, [key]: val }))
  }, [setFieldsVerify])

  async function submitHandle(event) {
    event.preventDefault()
    if (loading) return
    const res = await Promise.all(map(fieldVerify, async verify => {
      return await verify()
    }))
    if (includes(res, false)) return
    if (isFunction(onSubmit)) {
      onSubmit(formData)
    } else if (isFunction(poster)) {
      toggleLoading(true)
      setFormError()
      poster(formData)
        .catch(err => {
          setFormError(get(err, 'message', err))
        }).finally(() => toggleLoading(false))
    }
  }

  return (
    <FormContext.Provider value={{
      xs, sm, md, lg, xl,
      inline, labelWidth,
      rules, formData, loading, formError,
      changeField, pushFieldsVerify
    }}>
      <form onSubmit={submitHandle} className={classNames('', className)}>
        {children}
      </form>
    </FormContext.Provider>
  )
}