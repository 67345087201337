'use client'
import AppendRoot from '@component/appendRoot'
import classNames from 'classnames'
import { assign, get } from 'lodash-es'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useClickAway, useWindowScroll, useWindowSize } from 'react-use'

const TRIGGER = {
  hover: 'hover',
  click: 'click'
}

export default function Tooltip({
  children,
  content,
  active = false,
  disabled = false,
  top = false,
  right = false,
  bottom = false,
  left = false,
  dark = false,
  trigger = TRIGGER.hover,
  contentClassName,
  zIndex = 100
}) {

  const buttonRef = useRef()
  const contentRef = useRef()
  const innerRef = useRef()
  // 当前弹窗是否显示
  const [visible, toggleVisible] = useBoolean(active)
  // 当前元素bounds
  const { x, y } = useWindowScroll()
  const { width, height } = useWindowSize()
  const [bounds, setBounds] = useState()
  // 当前按钮的位置
  const offset = useMemo(() => {
    if (!bounds) return

    const { width, height, y, x } = bounds
    if (top) {
      return {
        x: x + width / 2,
        y: y - get(contentRef.current, 'offsetHeight', 0)
      }
    } else if (right) {
      return {
        x: x + width,
        y: y + height / 2
      }
    } else if (bottom) {
      return {
        x: x + width / 2,
        y: y + height
      }
    } else if (left) {
      return {
        x: x,
        y: y + height / 2
      }
    }
  }, [bounds, top, right, bottom, left, contentRef])
  // 机选当前按钮的边界信息
  const calcBounds = useCallback(() => {
    const { width, height, top, left } = buttonRef.current.getBoundingClientRect()
    setBounds({ width, height, y: top + y, x: left + x })
  }, [buttonRef, setBounds, x, y])

  useEffect(() => {
    calcBounds()
  }, [width, height, calcBounds])

  // 按钮事件
  const mouseHandle = useCallback(event => {
    calcBounds()
    if (disabled) return

    switch (event.type) {
      case 'click':
        if (trigger === TRIGGER.click) {
          toggleVisible(!visible)
        }
        break
      case 'mouseenter':
        if (trigger === TRIGGER.hover) {
          toggleVisible(true)
        }
        break
      case 'mouseleave':
        if (trigger === TRIGGER.hover) {
          toggleVisible(false)
        }
        break
    }
  }, [trigger, disabled, toggleVisible, visible, calcBounds])
  useEffect(() => toggleVisible(active), [active, toggleVisible])

  useEffect(() => {
    if (!visible || !contentRef.current) return
    const rect = contentRef.current.getBoundingClientRect()
    if (rect.left + rect.width > width) {
      innerRef.current.style.transform = `translateX(-${rect.left + rect.width - width + 10}px)`
    } else if (rect.left < 0) {
      innerRef.current.style.transform = `translateX(${Math.abs(rect.left) + 10}px)`
    } else {
      innerRef.current.style.transform = ''
    }
  }, [visible, contentRef, innerRef, width])

  useClickAway(buttonRef, (event) => {
    if (disabled || (contentRef.current && contentRef.current.contains(event.target))) return
    toggleVisible(false)
  })

  return (
    <>
      <div className={classNames('relative inline-block', { 'cursor-pointer': !disabled })} ref={buttonRef} onMouseEnter={mouseHandle} onMouseLeave={mouseHandle} onClick={mouseHandle}>
        {children}
      </div>
      <AppendRoot>
        <div
          ref={contentRef}
          onMouseEnter={mouseHandle}
          onMouseLeave={mouseHandle}
          className={classNames(
            'absolute transition-transform pointer-events-none',
            { 'pb-8 -translate-x-1/2 scale-y-0 origin-bottom': top },
            { 'pt-8 -translate-x-1/2 scale-y-0 origin-top': bottom },
            { 'scale-y-100 !pointer-events-auto': (top || bottom) && visible },

            { 'pl-12 -translate-y-1/2 scale-x-0 origin-left': right },
            { 'pr-12 -translate-y-1/2 -translate-x-full scale-x-0 origin-right': left },
            { 'scale-x-100 !pointer-events-auto': (left || right) && visible },
          )}
          style={assign({ zIndex }, offset ? { left: offset.x, top: offset.y } : undefined)}
        >
          <i className={classNames(
            'absolute border-8 border-solid border-transparent',
            { 'bottom-0 border-b-0 left-1/2 -translate-x-1/2 drop-shadow-menu-top': top },
            { 'top-0 border-t-0 left-1/2 -translate-x-1/2 drop-shadow-menu-bottom': bottom },
            { 'left-4 border-l-0 top-1/2 -translate-y-1/2 drop-shadow-menu-right': right },
            { 'right-4 border-r-0 top-1/2 -translate-y-1/2 drop-shadow-menu-left': left },
            top && (dark ? 'border-t-font' : 'border-t-white'),
            bottom && (dark ? 'border-b-font' : 'border-b-white'),
            right && (dark ? 'border-r-font' : 'border-r-white'),
            left && (dark ? 'border-l-font' : 'border-l-white'),
          )}></i>
          <div className={classNames('shadow-menu rounded-sm', dark ? 'bg-font text-white' : 'bg-white text-font', contentClassName)} ref={innerRef}>{content}</div>
        </div>
      </AppendRoot>
    </>
  )
}

Tooltip.propTypes = {
  trigger: PropTypes.oneOf([TRIGGER.hover, TRIGGER.click])
}