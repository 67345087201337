import { get, isString, map } from 'lodash-es'
import { useFormContext } from '../context'
import classNames from 'classnames'

export default function FormRow({
  label,
  labelRight,
  subText,
  errors,
  children,
  className,
  required = false,
  noError = false
}) {

  const { inline, labelWidth } = useFormContext()

  return (
    <div className={classNames({ 'flex flex-wrap items-center gap-x-10': inline }, className)}>
      {label && (
        <div className={classNames('flex items-center', inline ? 'justify-end' : 'justify-between')} style={inline ? { width: labelWidth } : undefined}>
          <span className='text-sm text-font'>{label}{required && <em className='text-red not-italic align-middle ml-4'>*</em>}</span>
          {labelRight && <span>{labelRight}</span>}
        </div>
      )}
      <div className={inline ? 'flex-1 min-w-0' : 'mt-8'}>
        {children}
      </div>
      {
        subText && (
          <div className={classNames({ 'w-full flex gap-x-10': inline })}>
            {
              inline && (
                <span className='inline-block h-10 flex-shrink-0' style={{ width: labelWidth }}></span>
              )
            }
            <div className='text-xs leading-12 mt-4 text-neutral-5'>{subText}</div>
          </div>
        )
      }
      {
        !noError && (
          <div className={classNames('min-h-[14px]', { 'w-full flex gap-x-10': inline })}>
            {
              inline && (
                <span className='inline-block h-10 flex-shrink-0' style={{ width: labelWidth }}></span>
              )
            }
            {
              map(isString(errors) ? [errors] : errors, (item, idx) => (
                <div key={idx} className='text-red text-sm leading-14'>{get(item, 'message', item)}</div>
              ))
            }
          </div>
        )
      }
    </div>
  )
}